<template>
    <b-overlay :show="showLoader">
        <b-card>
            <h2 style="font-weight: bold;">Izpolnite podatke o novici</h2>
            <validation-observer ref="validateEditNews">
                <b-form class="form mt-1">
                    <News ref="news" v-model="object" type="edit"/>
                    <b-form-group class="text-right">
                        <b-button variant="secondary" @click.prevent="validationForm">UREDI NOVICO</b-button>
                    </b-form-group>
                </b-form>
            </validation-observer>
        </b-card>
    </b-overlay>
</template>

<script>
    import { ValidationObserver } from 'vee-validate'
    import News from '../../Components/News'
    import {BButton, BCard, BForm, BFormGroup, BOverlay} from 'bootstrap-vue'
    export default {
        components: {
            ValidationObserver,
            News,
            BOverlay,
            BCard,
            BForm,
            BFormGroup,
            BButton
        },

        data() {
            return {
                object: {
                    title:'',
                    author_id:0,
                    date_added:'',
                    content:'',
                    additional_photos: [],
                    main_photo: '',
                    active: true
                },
                showLoader: false
            }
        },
        methods: {
            getNews() {
                const thisIns = this
                thisIns.showLoader = true
                const loadPromise = this.$http.get(`/api/management/v1/news/${thisIns.$route.params.news_id}`)
                loadPromise.then(function(response) {
                    thisIns.object = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.showLoader = false
                })
            },
            async validationForm() {
                this.$refs.validateEditNews.validate().then(async(success) => {
                    if (success) {
                        await this.editNews()
                    }
                })

            },
            async editNews() {
                this.showLoader = true

                try {
                    const isImageUploadSuccessfull = await this.$refs.news.$refs.imageSelector.UploadImagesToAPI()
                    if (!isImageUploadSuccessfull) {
                        this.$printError('Prišlo je do napake pri nalaganju slik.')
                        return
                    }
                    if (this.object.main_photo === '') {
                        this.$printWarning('Potrebno je dodati vsaj eno fotografijo')
                        return
                    }

                    this.object.author_id = this.$store.state.user.userData.id
                    this.object.date_added = new Date()

                    await this.$http.patch(`/api/management/v1/news/${this.$route.params.news_id}`, this.object)
                    this.$printSuccess('Novica je bila uspešno urejena!')
                    setTimeout(() => {
                        this.$router.push({name: 'admin-news-list'})
                    }, 1000)

                } catch (error) {
                    this.$printError(error.response.data)
                } finally {
                    this.showLoader = false
                }
            }
        },
        created() {
            this.getNews()
        }
    }
</script>
