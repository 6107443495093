<template>
    <div>
        <b-alert class="p-1 text-center" show variant="warning">
            {{ this.type === 'edit' ? 
                'Po spremembi novice, je potrebnih nekaj sekund, da so spremembe vidne. Če spremembe novice na seznamu niso vidne, poskusite osvežiti spletno stran.' :
                'Po oddaji novice, je potrebnih nekaj sekund, da je novica prikazana na seznamu. Če novice na seznamu ne vidite, poskusite osvežiti spletno stran.'
            }}
        </b-alert>
        <b-form-group>
            <b-form-checkbox switch v-model="object.active">Aktivno</b-form-checkbox>
        </b-form-group>
        <b-form-group>
            <b-form-checkbox switch  v-model="object.need_to_be_logged_in">Ali mora biti uporabnik prijavljen, da lahko vidi novico</b-form-checkbox>
        </b-form-group>
        <b-form-group label="Naslov">
            <validation-provider #default="{ errors }" name="naslov" rules="required|min:5|max:120">
                <b-form-input v-model="object.title" placeholder="Naslov"/>
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
        <b-form-group label="Opis">
            <validation-provider #default="{ errors }" name="opis" rules="required|min:5|max:15000">
                <TextAreaEditor v-model="object.content" />
                <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
        </b-form-group>
       
        <b-row align-h="center">
            <b-col class="text-center">
                <ImageSelector ref="imageSelector" :main_photo="object.main_photo" :additional_photos="object.additional_photos" @update-main-photo="updateMainPhoto" :type="type"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate'
    import { required, maxValue, minValue, max } from '@validations'
    import TextAreaEditor from '../Components/TextAreaEditor'
    import ImageSelector from '../Components/ImageSelector'
    import {BAlert, BCol, BFormCheckbox, BFormGroup, BFormInput, BRow} from 'bootstrap-vue'
    export default {
        components: {
            ValidationProvider,
            TextAreaEditor,
            ImageSelector,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BAlert
        },
        model: {
            prop: 'model',
            event: 'input'
        },
        props: {
            model: {
                type: Object,
                default: null
            },
            value: {
                default: null
            },
            type: {
                type: String
            }
        },
        data() {
            return {
                required,
                max,
                maxValue, 
                minValue
            }
        },
        methods: {
            updateMainPhoto(photo) {
                this.object.main_photo = photo
            }
        },
        computed: {
            object: {
                get() {
                    return this.model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>

<style scoped>

</style>